import './string';
import string from './string';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Date {
    toInputDateString(): string;
    toInputTimeString(): string;
    toInputDatetimeLocalString(): string;
    toString(format: string): string;
  }

  interface DateConstructor {
    parseData(value: Date|string|null): Date;
  }
}

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
Date.prototype.toInputDateString = function toInputDateString(): string {
  return this.toISOString().slice(0, this.toISOString().indexOf('T'));
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time
Date.prototype.toInputTimeString = function toInputTimeString(): string {
  return this.toISOString().slice(this.toISOString().indexOf('T'), this.toISOString().lastIndexOf(':'));
};

// Formatting for https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
Date.prototype.toInputDatetimeLocalString = function toInputDatetimeLocalString(): string {
  return this.toISOString().slice(0, this.toISOString().lastIndexOf(':'));
};

// Formatting extension https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toString
Date.prototype.toString = function toString(format: string = 'YYYY-MM-DD HH:mm:ss'): string {
  const dayNames: string[] = ['Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota', 'Nedeľa'];
  let lastChar: string = '';
  let formatPart: string = '';
  let formatParts: string[] = [];
  let ret: string = '';
  for (const fc of format) {
    if ((lastChar != '') && (lastChar != fc)) {
      formatParts.push(formatPart);
      formatPart = '';
    }
    formatPart += fc;
    lastChar = fc;
  }
  if (formatPart.length != 0) {
    formatParts.push(formatPart);
  }
  for (formatPart of formatParts) {
    switch (formatPart) {
      case 'YYYY':
        ret += this.getFullYear();
        break;
      case 'YY':
        ret += this.getFullYear() % 100;
        break;
      case 'MM':
        ret += (this.getMonth() + 1).toString().padStart(2, '0');
        break;
      case 'M':
        ret += this.getMonth() + 1;
        break;
      case 'DD':
        ret += this.getDate().toString().padStart(2, '0');
        break;
      case 'D':
        ret += this.getDate();
        break;
      case 'X': // day of week mon-sun
        ret += dayNames[this.getDay() - 1];
        break;
      case 'x': // day of week 1-7
        let dow = this.getDay();
        if (dow == 0) {
          dow = 7;
        }
        ret += dow;
        break;
      case 'HH':
        ret += this.getHours().toString().padStart(2, '0');
        break;
      case 'mm':
        ret += this.getMinutes().toString().padStart(2, '0');
        break;
      case 'ss':
        ret += this.getSeconds().toString().padStart(2, '0');
        break;
      case 'sss':
        ret += this.getMilliseconds().toString().padStart(3, '0');
        break;
      default:
        ret += formatPart;
    }
  }
  return ret;
};

// Simple funtion for parsing Date
Date.parseData = function parseData(value: Date|string|null): Date {
  if (String.isString(value)) {
    return new Date(value as string);
  }
  else if (value !== null) {
    return value as Date;
  }
  return new Date();
};

export default { Date };
