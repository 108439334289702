import unhead_IiIvU3SuKt from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xMNEnD1t59 from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_S8TXbZlTzl from "/var/www/html/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@1.8_ugkcjnvac3q4q2uc7izy2ymonu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_CTwEnUQuRV from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_i9ICAJXaAP from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Z906XCguXl from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2nHhTRoTX5 from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/html/.nuxt/components.plugin.mjs";
import prefetch_client_dH62ihJFEU from "/var/www/html/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_ioredis@5.4.1_magicast@0.3.5_rollup@_hf2uawdbtqo6p32akhbvbg35ai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_H0gO8VA2Ay from "/var/www/html/node_modules/.pnpm/@nuxt+icon@1.9.1_magicast@0.3.5_rollup@4.28.1_vite@5.4.11_@types+node@22.10.1_sass@1.82.0_ter_sekwesry43b4frp2ukon4z7lcu/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import types_FCoKSmB0ky from "/var/www/html/plugins/types.ts";
import window_mY4jZ4wBC5 from "/var/www/html/plugins/window.ts";
export default [
  unhead_IiIvU3SuKt,
  router_xMNEnD1t59,
  _0_siteConfig_S8TXbZlTzl,
  payload_client_CTwEnUQuRV,
  navigation_repaint_client_i9ICAJXaAP,
  check_outdated_build_client_Z906XCguXl,
  chunk_reload_client_2nHhTRoTX5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_dH62ihJFEU,
  plugin_H0gO8VA2Ay,
  types_FCoKSmB0ky,
  window_mY4jZ4wBC5
]