import { default as about9BEUa3hPycMeta } from "/var/www/html/pages/about.vue?macro=true";
import { default as contactZjsR3yTbTmMeta } from "/var/www/html/pages/contact.vue?macro=true";
import { default as indexUbNeHw0dYYMeta } from "/var/www/html/pages/index.vue?macro=true";
import { default as pricingpIbQutn3yiMeta } from "/var/www/html/pages/pricing.vue?macro=true";
import { default as teamVCVkv5ty0wMeta } from "/var/www/html/pages/team.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: about9BEUa3hPycMeta || {},
    redirect: "/",
    component: () => import("/var/www/html/pages/about.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactZjsR3yTbTmMeta || {},
    component: () => import("/var/www/html/pages/contact.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexUbNeHw0dYYMeta || {},
    component: () => import("/var/www/html/pages/index.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingpIbQutn3yiMeta || {},
    redirect: "/",
    component: () => import("/var/www/html/pages/pricing.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamVCVkv5ty0wMeta || {},
    redirect: "/",
    component: () => import("/var/www/html/pages/team.vue")
  }
]