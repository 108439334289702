// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface StringConstructor {
    isString(value: any): boolean;
  }
}

String.isString = function isString(value: any): boolean {
  return ((typeof value === 'string') || (value instanceof String));
}

export default { String };
