// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Number {
  }

  interface NumberConstructor {
    rndFloat(min: number, max: number): Number;
    rndInt(min: number, max: number): Number;
  }
}

Number.rndFloat = function rndFloat(min: number = Number.MIN_VALUE, max: number = Number.MAX_VALUE): Number {
  return new Number((Math.random() * (max - min + 1)) + min);
};

Number.rndInt = function rndInt(min: number = Number.MIN_SAFE_INTEGER, max: number = Number.MAX_SAFE_INTEGER): Number {
  return new Number(Math.floor(Math.random() * (max - min + 1)) + min);
};

export default { Number };
